/* eslint-disable custom-rules/no-color-hex,custom-rules/css-best-practices */
import React from 'react';
import {
  alpha,
  buttonBaseClasses,
  checkboxClasses,
  chipClasses,
  CssBaseline,
  iconButtonClasses,
  linearProgressClasses,
  paginationItemClasses,
  tableCellClasses,
  tableHeadClasses,
  tableRowClasses,
} from '@mui/material';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import 'react-grid-layout/css/styles.css';
import { APPBAR_HEIGHT } from './newNavigation/appbar.constants';

const colors = {
  neutrals: {
    100: '#FFFFFF',
    150: '#F7F9FA',
    200: '#F2F5F7',
    300: '#E9ECF0',
    350: '#E0E3E9',
    400: '#C2C8D3',
    500: '#949EB2',
    550: '#7D8699',
    600: '#5F687A',
    650: '#535C6D',
    700: '#484F61',
    800: '#363D4E',
    850: '#1F2535',
    900: '#0D1322',
  },
  primary: {
    100: '#EEF3FD',
    150: '#DBE6FC',
    200: '#C8D9FC',
    300: '#98B9FA',
    350: '#729FF9',
    400: '#427FF8',
    500: '#256CF7',
    600: '#1F5ACE',
    650: '#1948A5',
    700: '#13367C',
    800: '#0E295D',
    850: '#0B2049',
    900: '#050E20',
  },
  positive: {
    100: '#E6F3F1',
    200: '#C3E3DD',
    300: '#A4D5CC',
    400: '#75BFB2',
    500: '#3DA592',
    600: '#2D7A6C',
    700: '#1D4E45',
    800: '#173F37',
    900: '#112E29',
  },
  green: {
    100: '#E0F7DD',
    200: '#C2E5BD',
    300: '#A9D6A2',
    400: '#85C07D',
    500: '#62AB57',
    600: '#3F7B35',
    700: '#1E4E16',
    800: '#143F0F',
    900: '#0C3107',
  },
  negative: {
    100: '#FFE6E7',
    150: '#FADFE4',
    200: '#F4AEAC',
    300: '#EA7C78',
    400: '#E04B44',
    500: '#DC362E',
    600: '#B1322A',
    700: '#8E201C',
    800: '#671B1C',
    900: '#300906',
  },
  complementary: {
    100: '#FFFDE2',
    150: '#FFF9C2',
    200: '#FFF6A3',
    300: '#FFF17D',
    350: '#FCE656',
    400: '#FCDB35',
    500: '#FACD1D',
    600: '#F1B209',
    650: '#E8A000',
    700: '#DD8400',
    800: '#D36C00',
    850: '#C85600',
    900: '#BD4200',
  },
  orange: {
    100: '#FFF2E4',
    200: '#FAD2A4',
    300: '#F7BD7A',
    400: '#F4A84F',
    500: '#F19325',
    600: '#BE731C',
    700: '#8D5513',
    800: '#55320B',
    900: '#372005',
  },
  yellow: {
    100: '#FFF8DD',
    200: '#FEEEA3',
    300: '#FDE77C',
    400: '#FBDC42',
    500: '#FAD208',
    600: '#D0AE07',
    700: '#A68906',
    800: '#685205',
    900: '#3E2E04',
  },
  blue: {
    100: '#ECF7FF',
    200: '#C1E3FB',
    300: '#97CFF7',
    400: '#7AC3F5',
    500: '#28A9F1',
    600: '#2089C4',
    700: '#145B83',
    800: '#0E415E',
    900: '#0C2F42',
  },
  pink: {
    100: '#FDEAF6',
    200: '#FBC5DD',
    300: '#F87E9C',
    400: '#B62A5B',
    500: '#8B1A4B',
    600: '#761540',
    700: '#611036',
    800: '#4B0A2B',
    900: '#360520',
  },
  cyan: {
    100: '#E2F6FA',
    200: '#CBECF3',
    300: '#97D9EA',
    400: '#6AC9E5',
    500: '#25BAE2',
    600: '#1D98B9',
    700: '#11677E',
    800: '#0C4A5B',
    900: '#093540',
  },
  purple: {
    100: '#F4E8FE',
    200: '#EDD8FD',
    300: '#D3ACF2',
    400: '#AD6FDD',
    500: '#9F46D7',
    600: '#8446AF',
    700: '#60387C',
    800: '#441460',
    900: '#2B083F',
  },
};
const gradients = {
  red: 'linear-gradient(271deg, #E04B44 18.91%, #F4AEAC 141.41%)',
  orange: 'linear-gradient(271deg, #F19325 18.91%, #FAD2A4 141.41%)',
  yellow: 'linear-gradient(271deg, #F1B209 18.91%, #FCDB35 141.41%)',
  positive: 'linear-gradient(271deg, #3DA592 18.91%, #C3E3DD 141.41%)',
  blue: 'linear-gradient(271deg, #28A9F1 18.91%, #C1E3FB 141.41%)',
  grey: 'linear-gradient(271deg, #949EB2 18.91%, #F2F5F7 141.41%)',
  primary: 'linear-gradient(271deg, #256CF7 18.91%, #C8D9FC 141.41%)',
  purple: 'linear-gradient(271deg, #948BFF 18.91%, #D4D3FF 141.41%)',
  green: 'linear-gradient(271deg, #62AB57 18.91%, #C2E5BD 141.41%)',
};
export const SCATTER_COLORS = [
  '#5588FB',
  '#7ACFE7',
  colors.purple[400],
  '#F6CD6C',
  '#96D0C6',
  '#8953EB',
  '#D579E7',
  '#2393D7',
  '#F79962',
  '#D7C4FC',
  '#88AAE8',
  '#9A2CAF',
  '#C9DCFF',
  colors.cyan[500],
  '#BA561F',
  '#005F99',
  '#AB7FCC',
  '#C1CD38',
  '#7B7BEE',
  '#732FE4',
];
const chartColors = [
  colors.primary['650'],
  colors.primary['400'],
  colors.primary['300'],
  colors.primary['200'],
  colors.cyan['300'],
  colors.cyan['500'],
  colors.cyan['600'],
  colors.cyan['700'],
  colors.purple['300'],
  colors.purple['400'],
  colors.purple['500'],
  colors.purple['700'],
  colors.purple['800'],
];
const avatarColors = [
  '#5588FB',
  '#7ACFE7',
  colors.purple[400],
  '#F79962',
  '#D579E7',
  '#8953EB',
  '#88AAE8',
  '#9A2CAF',
  '#25BAE2',
  '#E34C75',
  '#7998AB',
  '#AB7FCC',
  '#74BFB0',
  '#7B7BEE',
  '#C1BDC6',
  '#EF5EA3',
  '#E4B12F',
  '#A3C9DA',
  '#FCDB35',
  '#4DC69E',
  '#848EDA',
  '#D07D94',
  '#809CFF',
];
export const PIE_PALETTES = {
  purple: [colors.purple[500], colors.purple[300], colors.purple[200], colors.purple[800], colors.purple[600]],
  primary: [colors.primary[400], colors.primary[300], colors.primary[200], colors.primary[700], colors.primary[600]],
  cyan: [colors.cyan[500], colors.cyan[300], colors.cyan[200], colors.cyan[700], colors.cyan[600]],
};
export const flex = {
  row: {
    display: 'flex',
  },
  wrap: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  colCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  colCenterBetween: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  colJustifyCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  colJustifyBetween: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  colJustifyAround: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  colItemsStart: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  colItemsCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  colItemsEnd: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  colStartCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
  },
  colEndCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    alignItems: 'center',
  },
  colEndStart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    alignItems: 'start',
  },
  colEnd: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    alignItems: 'end',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  reverseEnd: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
  },
  justifyBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  justifyBetweenCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  justifyStartEnd: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  justifyBetweenStart: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  justifyStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  justifyBetweenEnd: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  justifyAround: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  justifyAroundCenter: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  justifyEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  justifyEndCenter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  justifyStartCenter: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  justifyCenterBaseline: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  itemsCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  itemsEnd: {
    display: 'flex',
    alignItems: 'end',
  },
  itemsStart: {
    display: 'flex',
    alignItems: 'start',
  },
  itemsBaseline: {
    display: 'flex',
    alignItems: 'baseline',
  },
  alignEnd: {
    display: 'flex',
    alignItems: 'flex-start',
  },
};
const defaultTheme = createTheme({
  transitions: {
    duration: {
      shortest: 50,
      shorter: 100,
      short: 150,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
  },
  palette: {
    colors,
    chartColors: [...chartColors, ...chartColors, ...chartColors, ...chartColors],
    avatarColors,
    primary: {
      main: colors.primary[500],
      contrastText: colors.neutrals[100],
    },
    unknown: {
      main: colors.positive[500],
    },
    info: {
      main: colors.positive[500],
    },
    low: {
      main: colors.yellow[500],
    },
    success: {
      main: colors.positive[500],
    },
    warning: {
      main: colors.orange[500],
    },
    high: {
      main: colors.negative[500],
    },
    error: {
      main: colors.pink[500],
    },
    text: {
      primary: colors.neutrals[800],
    },
    white: {
      main: colors.neutrals[100],
    },
    action: {
      disabled: colors.neutrals[400],
      hover: colors.neutrals[200],
      focus: colors.neutrals[200],
    },
    gradients,
  },
  typography: {
    fontFamily: 'Inter',
    button: {
      textTransform: 'none',
    },
    body1: {
      fontSize: '14px',
    },
    h1: {
      fontSize: '48px',
      lineHeight: 1.5,
      fontWeight: 300,
    },
    h2: {
      fontSize: '32px',
      lineHeight: 1.5,
      fontWeight: 600,
    },
    h3: {
      fontSize: '24px',
      lineHeight: 1.5,
      fontWeight: 600,
      marginBottom: '24px',
    },
    h4: {
      fontSize: '24px',
      lineHeight: 1.5,
      fontWeight: 300,
    },
    h5: {
      fontSize: '18px',
      lineHeight: '22px',
      fontWeight: 600,
    },
    h6: {
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 600,
    },
    h7: {
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 600,
      textTransform: 'uppercase',
      letterSpacing: '0.4px',
      color: colors.neutrals[500],
    },
    h8: {
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 600,
      textTransform: 'uppercase',
      letterSpacing: '0.7px',
      color: colors.neutrals[500],
    },
  },
});
const appTheme = createTheme({
  ...defaultTheme,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: colors.neutrals[150],
          fontFamily: "'Inter', 'Roboto', 'sans-serif'",
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          overscrollBehavior: 'none',
          overflow: 'hidden',
        },
        a: {
          textDecoration: 'none',
          color: 'inherit',
        },
        svg: {
          color: 'inherit',
          flexShrink: 0,
          height: 20,
          width: 20,
          pointerEvents: 'none',
        },
        'input:-webkit-autofill, input:-webkit-autofill:focus': {
          transition: 'background-color 600000s 0s, color 600000s 0s',
          borderRadius: 0,
        },
        '.recharts-tooltip-wrapper': {
          outline: 'none',
        },
        '.recharts-layer.recharts-pie, .recharts-sector:focus, .recharts-layer .recharts-pie-sector': {
          outline: 'none !important',
          outlineColor: 'none',
          outlineStyle: 'none',
        },
        '.custom-venn-tooltip': {
          transform: 'none !important',
        },
        mark: {
          background: colors.complementary[150],
        },
        '.avalor-full-screen': {
          position: 'fixed !important',
          inset: '0px 0px 0px 0px !important',
          transform: `translate3d(calc(var(--side-bar-width) + 24px), ${APPBAR_HEIGHT + 24}px, 0px) !important`,
          zIndex: defaultTheme.zIndex.modal,
          width: `calc(100vw - var(--side-bar-width) - ${24 * 2}px)`,
          height: `calc(100vh - ${APPBAR_HEIGHT + 24 * 2}px) !important`,
        },
        fallbacks: [
          {
            '@font-face': {
              fontFamily: 'Inter',
              src: 'url("/Inter-VariableFont_slnt,wght.ttf") format("truetype-variations")',
              fontWeight: '1 999',
            },
          },
          {
            '@font-face': {
              fontFamily: 'Outfit',
              src: 'url("/Outfit-SemiBold.ttf") format("truetype")',
            },
          },
        ],
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: `${colors.neutrals[500]}48`,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          gap: 8,
          '&.MuiIconButton-root': {
            padding: 0,
            '&, &:hover': {
              backgroundColor: 'transparent',
            },
            ':before': {
              content: '""',
              position: 'absolute',
              padding: 4,
              width: '100%',
              height: '100%',
              boxSizing: 'content-box',
              borderRadius: 10,
              backgroundColor: 'transparent',
              transition: defaultTheme.transitions.create('background-color', {
                duration: defaultTheme.transitions.duration.shorter,
              }),
            },
            '&:hover:before': {
              backgroundColor: alpha(colors.neutrals[800], 0.04),
            },
            svg: {
              width: 18,
              height: 18,
              position: 'relative', // needed for background positioning
            },
          },
          '&.Mui-disabled img': {
            opacity: 0.4,
          },
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          width: 266,
          height: 270,
          '.MuiPickersDay-root, .MuiTypography-root': {
            width: 26,
            height: 26,
            borderRadius: '10px',
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'text' },
          style: {
            color: colors.neutrals[600],
            padding: 0,
            '&:hover, &.Mui-focusVisible': {
              backgroundColor: 'inherit',
              color: colors.primary[500],
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            color: colors.neutrals[700],
            backgroundColor: colors.neutrals[100],
            borderColor: colors.neutrals[400],
            '&:hover': {
              backgroundColor: `${colors.primary[500]}0D`,
              borderColor: `${colors.primary[500]}`,
            },
            '&.Mui-disabled': {
              color: colors.neutrals[500],
              borderColor: colors.neutrals[400],
            },
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            '&:hover': {
              '&.MuiButton-containedPrimary': {
                backgroundColor: colors.primary[600],
              },
              boxShadow: `0 4px 10px ${colors.primary[500]}40`,
            },
            '&.Mui-disabled': {
              backgroundColor: colors.neutrals[300],
            },
          },
        },
        {
          props: { size: 'xSmall' },
          style: {
            gap: '4px',
            fontSize: 13,
            lineHeight: '18px',
            borderRadius: '8px',
            padding: '3px 10px',
            [`.${chipClasses.root}`]: {
              background: colors.primary[100],
              height: 18,
              [`.${chipClasses.label}`]: {
                paddingLeft: 6,
                paddingRight: 6,
              },
            },
            svg: {
              height: 18,
              width: 18,
            },
          },
        },
        {
          props: { size: 'small' },
          style: {
            fontSize: 14,
            lineHeight: '22px',
            borderRadius: 10,
            padding: '5px 16px',
          },
        },
        {
          props: { size: 'large' },
          style: {
            padding: '12px 24px',
          },
        },
        {
          // @ts-ignore
          props: { size: 'small', type: 'icon' },
          style: {
            padding: '6px',
          },
        },
        {
          // @ts-ignore
          props: { size: 'large', type: 'icon' },
          style: {
            padding: '8px',
          },
        },
        {
          // @ts-ignore
          props: { active: true },
          style: {
            backgroundColor: colors.primary[150],
            '&.MuiButton-text': {
              color: colors.primary[500],
            },
          },
        },
        {
          props: { size: 'small', variant: 'outlined' },
          style: {
            padding: '4px 16px',
          },
        },
        {
          props: { size: 'xSmall', variant: 'outlined' },
          style: {
            padding: '2px 10px',
          },
        },
        {
          // @ts-ignore
          props: { size: 'xSmall', type: 'icon' },
          style: {
            padding: '3px',
            lineHeight: '18px',
            borderRadius: '8px',
          },
        },
        {
          // @ts-ignore
          props: { size: 'xSmall', type: 'icon', variant: 'outlined' },
          style: {
            padding: '2px',
          },
        },
        {
          // @ts-ignore
          props: { size: 'small', type: 'icon', variant: 'outlined' },
          style: {
            padding: '6px',
          },
        },
        {
          props: { color: 'error', variant: 'contained' },
          style: {
            backgroundColor: colors.negative[400],
          },
        },
        {
          // @ts-ignore
          props: { variant: 'text', active: true },
          style: {
            backgroundColor: 'transparent',
          },
        },
        {
          // @ts-ignore
          props: { variant: 'outlined', active: true },
          style: {
            backgroundColor: colors.primary[150],
            border: `1px solid ${colors.primary[500]}`,
          },
        },
        {
          // @ts-ignore
          props: { variant: 'contained', active: true },
          style: {
            backgroundColor: colors.primary[650],
          },
        },
        {
          props: { variant: 'link' },
          style: {
            padding: 0,
            backgroundColor: 'transparent',
            ':hover': {
              color: colors.primary[500],
              backgroundColor: 'transparent',
            },
          },
        },
        {
          // @ts-ignore
          props: { variant: 'filter', active: true },
          style: {
            backgroundColor: colors.neutrals[350],
            '&.transparent, &.active-filter': {
              color: colors.neutrals[800],
              backgroundColor: colors.neutrals[350],
            },
          },
        },
        {
          props: { variant: 'primaryText' },
          style: {
            color: colors.primary[500],
            padding: 0,
            backgroundColor: 'transparent',
            ':hover': {
              color: colors.primary[650],
              backgroundColor: 'transparent',
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          boxShadow: 'none',
          fontWeight: 600,
          fontSize: 14,
          lineHeight: 1.5,
          borderRadius: 12,
          padding: '8px 16px',
          minWidth: 'inherit',
        },
        // @ts-ignore
        filter: {
          paddingTop: '5px',
          paddingBottom: '5px',
          borderRadius: '10px',
          lineHeight: '21px',
          color: colors.neutrals[650],
          backgroundColor: alpha(colors.neutrals[350], 0.8),
          '&.transparent': {
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: colors.neutrals[300],
            },
          },
          '&:hover': {
            color: colors.neutrals[700],
            backgroundColor: colors.neutrals[350],
          },
          '&.active-filter': {
            color: colors.neutrals[800],
            backgroundColor: colors.primary[150],
            '&.transparent': {
              backgroundColor: 'transparent',
            },
            '&.Mui-disabled': {
              color: colors.neutrals[500],
            },
          },
          '&.Mui-focusVisible': {
            backgroundColor: colors.neutrals[350],
            color: colors.neutrals[800],
            '&.active-filter:not(.transparent)': {
              backgroundColor: colors.primary[200],
            },
          },
          [`.${chipClasses.root}`]: {
            background: colors.primary[100],
            height: 20,
            [`.${chipClasses.label}`]: {
              paddingLeft: 6,
              paddingRight: 6,
            },
          },
          '&.placeholder span': {
            color: colors.neutrals[600],
          },
          svg: {
            width: 16,
            height: 16,
          },
        },
        external: {
          paddingTop: '2px',
          paddingBottom: '2px',
          borderRadius: '10px',
          lineHeight: '20px',
          height: '24px',
          backgroundColor: colors.neutrals[300],
          '&:hover': {
            backgroundColor: colors.neutrals[350],
          },
        },
      },
    },
    MuiLinearProgress: {
      variants: [
        {
          props: { color: 'secondary' },
          style: {
            [`&.${linearProgressClasses.root}`]: {
              background: colors.neutrals[300],
            },
            [`.${linearProgressClasses.barColorSecondary}`]: {
              background: colors.neutrals[400],
            },
          },
        },
      ],
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          color: 'inherit',
          transition: defaultTheme.transitions.create(['color', 'background-color'], {
            duration: defaultTheme.transitions.duration.shorter,
          }),
          '&:hover, &.Mui-focusVisible': {
            backgroundColor: colors.neutrals[350],
          },
          '&.MuiToggleButton-white': {
            color: colors.neutrals[650],
            backgroundColor: alpha(colors.neutrals[350], 0.8),
            '&:hover': {
              color: colors.neutrals[800],
            },
            fontWeight: 600,
            fontSize: 14,
            borderRadius: 10,
            padding: '3px 12px',
            '.MuiToggleButtonGroup-root &.MuiToggleButtonGroup-grouped': {
              borderColor: 'transparent',
            },
            '&.MuiToggleButtonGroup-grouped:not(.Mui-selected)': {
              '&:not(:first-of-type)': { paddingLeft: '26px', marginLeft: '-18px' },
              '&:not(:last-of-type)': { paddingRight: '23px', marginRight: '-14px' },
              '&:has(+ .MuiToggleButtonGroup-grouped:not(.Mui-selected))': { marginRight: '3px' },
              '+ .MuiToggleButtonGroup-grouped:not(.Mui-selected)': {
                paddingLeft: '12px',
                paddingRight: '12px',
                '&:not(:last-of-type)': { marginRight: '-3px' },
              },
              '.Mui-selected:nth-of-type(1) ~ &:nth-of-type(4)': { marginLeft: '3px' },
              '&:not(:first-of-type):not(:last-of-type):has(+ .MuiToggleButtonGroup-grouped:nth-of-type(4).Mui-selected)': {
                marginLeft: '2px',
              },
            },
            '&.MuiToggleButtonGroup-grouped.Mui-selected': {
              backgroundColor: colors.neutrals[100],
              color: colors.neutrals[800],
              borderColor: colors.neutrals[400],
              borderRadius: 10,
              zIndex: 1,
              pointerEvents: 'none',
            },
          },
          '&.MuiToggleButton-standard': {
            '&.MuiToggleButtonGroup-grouped.Mui-selected': {
              backgroundColor: colors.primary[150],
              '&:hover': {
                backgroundColor: colors.primary[200],
              },
            },
          },
        },
        // @ts-ignore
        sizeXSmall: {
          minHeight: 24,
          borderRadius: '10px',
          padding: '3px 11px',
          fontSize: 12,
          // white variant
          '&.MuiToggleButton-white': {
            backgroundColor: colors.neutrals[300],
            color: colors.neutrals[600],
            '&:hover': {
              color: colors.neutrals[800],
            },
            fontWeight: 600,
            fontSize: 12,
            borderRadius: 10,
            padding: '3px 11px',
            '.MuiToggleButtonGroup-root &.MuiToggleButtonGroup-grouped': {
              borderColor: 'transparent',
            },
            '&.MuiToggleButtonGroup-grouped.Mui-selected': {
              backgroundColor: colors.neutrals[100],
              color: colors.neutrals[800],
              borderColor: colors.neutrals[400],
              borderRadius: 10,
              zIndex: 1,
            },
          },
          '&, .MuiToggleButtonGroup-root &.MuiToggleButtonGroup-grouped': {
            '&.Mui-disabled': {
              backgroundColor: colors.neutrals[300],
              color: colors.neutrals[500],
              svg: {
                color: colors.neutrals[500],
              },
            },
            borderColor: 'transparent',
            fontWeight: 600,
            lineHeight: '1.5',
            '&:not(.MuiToggleButton-white.Mui-selected):not(:first-of-type)': {
              borderLeft: `1px solid ${colors.neutrals[350]}`,
            },
          },
          '&:not(.Mui-selected)': {
            backgroundColor: colors.neutrals[300],
            color: colors.neutrals[600],
            '&:hover': {
              backgroundColor: colors.neutrals[350],
              color: colors.neutrals[700],
            },
          },
          '&.Mui-selected': {
            backgroundColor: colors.primary[150],
            '&:hover': {
              backgroundColor: colors.primary[150],
              color: colors.neutrals[700],
            },
            color: colors.neutrals[700],
            svg: {
              color: colors.neutrals[700],
            },
          },
          svg: {
            width: 16,
            height: 16,
          },
        },
        sizeSmallOutline: { height: 32 },
        sizeSmall: {
          minHeight: 32,
          borderRadius: '10px',
          padding: '5px 11px',
          fontSize: 14,
          '&, .MuiToggleButtonGroup-root &.MuiToggleButtonGroup-grouped': {
            borderColor: 'transparent',
            fontWeight: 600,
            lineHeight: '1.5',
            '&:not(.MuiToggleButton-white.Mui-selected):not(:first-of-type)': {
              borderLeft: `1px solid ${colors.neutrals[350]}`,
            },
          },
          '&:not(.Mui-selected)': {
            backgroundColor: colors.neutrals[300],
            color: colors.neutrals[600],
            '&:hover': {
              backgroundColor: colors.neutrals[350],
              color: colors.neutrals[700],
            },
          },
          '&.Mui-selected': {
            backgroundColor: colors.primary[150],
            '&:hover': {
              backgroundColor: colors.primary[150],
              color: colors.neutrals[700],
            },
            color: colors.neutrals[800],
            svg: {
              color: colors.primary[500],
            },
          },
          svg: {
            width: 18,
            height: 18,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: alpha(colors.primary[150], 0.7),
            '&:hover': {
              backgroundColor: colors.primary[150],
            },
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 3,
      },
      styleOverrides: {
        root: {
          boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
        },
        rounded: {
          borderRadius: 2,
        },
      },
    },
    MuiDivider: {
      variants: [
        {
          props: { variant: 'normal' },
          style: {
            marginTop: '4px',
            marginBottom: '4px',
          },
        },
      ],
      styleOverrides: {
        root: {
          borderColor: colors.neutrals[350],
        },
        light: {
          borderColor: colors.neutrals[200],
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: '8px',
          '& .MuiDivider-root': {
            margin: '8px',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { size: 'medium' },
          style: {
            margin: '0',
            height: '100%',
            padding: '2px 8px 2px 10px',
            backgroundColor: colors.primary[150],
            zIndex: 1,
            '& .MuiChip-label': {
              fontSize: 14,
              lineHeight: '22px',
              padding: 0,
              marginRight: 16,
            },
            '& .MuiChip-deleteIcon': {
              color: colors.primary[400],
              pointerEvents: 'inherit',
              '&:hover': {
                color: colors.primary[600],
              },
            },
            '&.Mui-disabled': {
              opacity: 1,
              backgroundColor: colors.neutrals[300],
              color: colors.neutrals[500],
              '& .MuiChip-deleteIcon': {
                color: colors.neutrals[400],
              },
            },
          },
        },
      ],
    },
    MuiAutocomplete: {
      variants: [
        {
          // @ts-ignore
          props: { size: 'xSmall' },
          style: {
            '& .MuiFormLabel-root.MuiInputLabel-root': {
              marginBottom: '3px',
              fontSize: 12,
              color: colors.neutrals[550],
            },
            '& .MuiInputBase-root': {
              minHeight: 24,
              padding: '0px 10px',
              fontSize: 12,
              '& .MuiIconButton-root': {
                svg: {
                  width: 16,
                  height: 16,
                  position: 'relative',
                  color: colors.neutrals[600],
                },
              },
              '& .MuiOutlinedInput-notchedOutline': {
                minHeight: 24,
                borderRadius: '8px',
              },
            },
            // multiLine
            '&.autocomplete-multiple': {
              '& .MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
                gap: '4px',
                padding: '4px 30px 4px 8px',
                '&:not(.MuiInputBase-sizeSmall) .MuiAutocomplete-input': {
                  paddingLeft: '8px',
                  height: 16,
                  lineHeight: '16px',
                },
              },
            },
            '& .MuiAutocomplete-tag': {
              margin: '0',
              height: '100%',
              padding: '2px 8px 2px 10px',
              backgroundColor: colors.primary[150],
              zIndex: 1,
              '& .MuiChip-label': {
                fontSize: 12,
                lineHeight: '16px',
                padding: 0,
                marginRight: '8px',
              },
              // '& .MuiChip-deleteIcon': {
              //   color: newColors.primary[400],
              //   pointerEvents: 'inherit',
              //   '&:hover': {
              //     color: newColors.primary[600],
              //   },
              // },
              // '&.Mui-disabled': {
              //   opacity: 1,
              //   backgroundColor: newColors.neutrals[300],
              //   color: newColors.neutrals[500],
              //   '& .MuiChip-deleteIcon': {
              //     color: newColors.neutrals[400],
              //   },
              // },
            },
            '& .MuiOutlinedInput-root': {
              paddingLeft: '10px',
              '& .MuiAutocomplete-input': {
                padding: 0,
                '& + .MuiAutocomplete-endAdornment': {
                  top: 'inherit',
                  right: '8px',
                  [`.${iconButtonClasses.root}`]: {
                    margin: '0 0px',
                  },
                },
              },
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          maxWidth: 300,
          width: '100%',
          '&.autocomplete-multiple': {
            '&, & .MuiFormControl-root': {
              maxWidth: 450,
            },
            '& .MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
              gap: '5px',
              padding: '7px 30px 7px 8px',
              '&:not(.MuiInputBase-sizeSmall) .MuiAutocomplete-input': {
                paddingLeft: '8px',
                height: 26,
                lineHeight: '22px',
              },
              '&.Mui-disabled': {
                '.MuiOutlinedInput-notchedOutline': {
                  backgroundColor: 'inherit',
                  border: `1px solid ${colors.neutrals[400]}`,
                },
                img: {
                  opacity: 0.3,
                },
              },
            },
          },
          '& .MuiOutlinedInput-root': {
            paddingLeft: '16px',
            '&.MuiInputBase-root.MuiInputBase-sizeSmall': {
              padding: '5px 30px 5px 16px',
              '& .MuiAutocomplete-endAdornment svg': {
                width: 16,
                height: 16,
              },
              '& .MuiAutocomplete-input': {
                padding: 0,
              },
            },
            '& .MuiAutocomplete-input': {
              padding: 0,
              '& + .MuiAutocomplete-endAdornment': {
                top: 'inherit',
                right: '12px',
                [`.${iconButtonClasses.root}`]: {
                  margin: '0 2px',
                },
              },
            },
          },
          '&.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root': {
            paddingRight: '49px',
          },
        },
        paper: {
          padding: '8px',
          boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
        },
        listbox: {
          padding: 0,
          '.MuiAutocomplete-option': {
            paddingLeft: '8px',
            '&.has-checkbox[aria-selected="true"]': {
              backgroundColor: 'transparent',
            },
          },
        },
        option: {
          gap: '8px',
        },
        groupLabel: {
          ...defaultTheme.typography.h7,
          lineHeight: '40px',
          padding: '0 8px',
        },
        clearIndicator: {
          padding: 2,
        },
        groupUl: {
          '.MuiAutocomplete-option': {
            paddingLeft: '16px',
          },
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { size: 'xSmall' },
          style: {
            '& .MuiFormLabel-root.MuiInputLabel-root': {
              marginBottom: '3px',
              fontSize: 12,
              color: colors.neutrals[550],
            },
            '& .MuiInputBase-root': {
              fontSize: 12,
              minHeight: 24,
              padding: '0px 10px',
              '& .MuiOutlinedInput-notchedOutline': {
                minHeight: 24,
                borderRadius: '8px',
              },
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          maxWidth: 300,
          width: '100%',
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: {
          transform: 'none',
          position: 'initial',
          fontSize: '13px',
          lineHeight: 1.5,
          marginBottom: '4px',
          '&, &.Mui-disabled': {
            color: colors.neutrals[600],
          },
          '&:not(.Mui-disabled) .MuiInputLabel-asterisk': {
            color: colors.negative[500],
          },
        },
      },
    },
    MuiOutlinedInput: {
      variants: [
        {
          // @ts-ignore
          props: { size: 'xSmall' },
          style: {
            '&.MuiInputBase-multiline': {
              minHeight: 24,
              padding: '0px 10px',
              '& .MuiOutlinedInput-notchedOutline': {
                minHeight: 24,
                borderRadius: '8px',
              },
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          lineHeight: 1.5,
          width: '100%',
          borderRadius: 12,
          color: defaultTheme.palette.text.primary,
          fontWeight: 400,
          fontSize: 14,
          minHeight: '40px',
          padding: '7px 15px',
          '&.MuiInputBase-sizeSmall': {
            minHeight: '32px',
            padding: '3px 15px',
            '& .MuiOutlinedInput-notchedOutline': {
              borderRadius: 10,
              minHeight: '32px',
            },
          },
          '&.MuiInputBase-sizeLarge': {
            // sizeLarge doesn't exist
            fontSize: 16,
            minHeight: '48px',
            padding: '12px 15px',
          },
          '&.MuiInputBase-multiline': {
            padding: '9.5px 15px',
            '&.MuiInputBase-sizeSmall': {
              minHeight: '32px',
              padding: '3px 15px',
            },
          },
          '&.MuiInputBase-root': {
            gap: 8,
          },
          '& .MuiInputBase-input': {
            zIndex: 1,
            padding: 0,
            textOverflow: 'ellipsis',
            '&.Mui-disabled': {
              color: colors.neutrals[500],
              textFillColor: 'inherit',
            },
            '&::placeholder': {
              color: colors.neutrals[600],
              opacity: 1,
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.neutrals[400]}`,
            width: '100%',
            transition: defaultTheme.transitions.create(['border-color', 'background-color']),
            top: 0,
            '& legend': {
              height: 0,
              width: 0,
            },
            borderRadius: 12,
            minHeight: '40px',
          },
          '& svg': {
            width: 18,
            height: 18,
          },
          '&.MuiInputBase-sizeLarge .MuiOutlinedInput-notchedOutline': {
            // sizeLarge doesn't exist
            minHeight: '48px',
          },
          '&:not(.Mui-focused):not(.Mui-error):hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.neutrals[800],
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
          },
          '&.Mui-disabled': {
            '& .MuiButtonBase-root': {
              pointerEvents: 'none',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
              backgroundColor: colors.neutrals[200],
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          '&.Mui-disabled': {
            color: colors.neutrals[500],
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 32,
          height: 16,
          borderRadius: 12,
          padding: 0,
          '& .MuiSwitch-track': {
            backgroundColor: colors.neutrals[400],
            transition: defaultTheme.transitions.create(['background-color'], {
              duration: defaultTheme.transitions.duration.shorter,
            }),
          },
          '&:hover .MuiSwitch-switchBase:not(.Mui-disabled), .Mui-focusVisible': {
            '&:not(.Mui-checked) + .MuiSwitch-track': {
              backgroundColor: colors.neutrals[500],
            },
            '&.Mui-checked + .MuiSwitch-track': {
              backgroundColor: colors.primary[600],
            },
          },
          '& .MuiSwitch-switchBase': {
            padding: 3,
            '&.Mui-checked': {
              transform: 'translateX(16px)',
            },
            '& + .MuiSwitch-track, &.Mui-checked + .MuiSwitch-track': {
              opacity: 1,
            },
            '&.Mui-disabled': {
              '+ .MuiSwitch-track': {
                opacity: 0.4,
              },
              '.MuiSwitch-thumb': {
                boxShadow: 'none',
              },
            },
            '.MuiSwitch-thumb': {
              color: colors.neutrals[100],
              width: 10,
              height: 10,
            },
          },
          '& + .MuiTypography-root': {
            lineHeight: '22px',
            transition: defaultTheme.transitions.create(['color'], {
              duration: defaultTheme.transitions.duration.shorter,
            }),
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          color: colors.neutrals[500],
          transition: defaultTheme.transitions.create(['color'], {
            duration: defaultTheme.transitions.duration.shorter,
          }),
          '&:hover, &.Mui-focusVisible': {
            backgroundColor: 'inherit',
            color: colors.primary[650],
          },
          '&:not(.Mui-checked):not(.MuiCheckbox-indeterminate)': {
            '&:hover, &.Mui-focusVisible': {
              color: colors.neutrals[700],
            },
          },
          '& svg': {
            width: 16,
            height: 16,
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          width: 10,
          height: 10,
          minWidth: 10,
          padding: 5,
          right: 4,
          top: 4,
          color: colors.primary[500],
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          transition: defaultTheme.transitions.create(['background-color'], {
            duration: defaultTheme.transitions.duration.short,
          }),
          '&.MuiTableRow-hover:not(:hover) > .MuiTableCell-root.editable:not(.edit-mode) .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '&.Mui-selected': {
            backgroundColor: alpha(colors.primary[150], 0.7),
            '&.MuiTableRow-hover:hover': {
              backgroundColor: colors.primary[150],
            },
          },
          '&.active-row': {
            outline: `2px solid ${colors.primary[500]}`,
            outlineOffset: '-2px',
            borderRadius: '4px',
          },
          cursor: 'default',
          '&.editable-row': {
            cursor: 'pointer',
          },
          '& a': {
            transition: defaultTheme.transitions.create(['color'], {
              duration: defaultTheme.transitions.duration.shortest,
            }),
          },
          '&:not(.expanded-row-content):hover > .MuiTableCell-root:not(:has(svg)) a': {
            color: colors.primary[500],
            textDecoration: 'underline',
          },
          '&:last-of-type > .MuiTableCell-root': {
            borderBottomColor: colors.neutrals[400],
          },
          '&.has-expandedRow .MuiTableCell-root': {
            cursor: 'pointer',
            borderBottom: 'none',
          },
          '&:has(.Mui-focusVisible)': {
            backgroundColor: defaultTheme.palette.action.hover,
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          [`.${buttonBaseClasses.root}.${paginationItemClasses.root}.${paginationItemClasses.selected}`]: {
            background: 'transparent',
          },
          [`.${buttonBaseClasses.root}.${paginationItemClasses.root}.${paginationItemClasses.page}:hover`]: {
            backgroundColor: colors.neutrals[300],
            opacity: 0.8,
          },
        },
      },
    },
    MuiTable: {
      variants: [
        {
          props: { size: 'xSmall' },
          style: {
            'thead tr:first-of-type .MuiTableCell-head': {
              ...defaultTheme.typography.h7,
            },
            '.MuiTableCell-head': {
              lineHeight: '1.25rem',
            },
            '.MuiTableCell-root': {
              height: 32,
              fontSize: 12,
              padding: '5px 12px',
              whiteSpace: 'nowrap',
              '&:not(.MuiTableCell-head):not(.totals-row)': {
                borderBottomColor: colors.neutrals[300],
              },
              svg: {
                height: 18,
                width: 18,
              },
              '&:last-of-type > .inline-actions-cell': {
                backgroundImage: `linear-gradient(to right, transparent, ${colors.neutrals[300]} 20%)`,
              },
              [`.${checkboxClasses.root} svg`]: {
                width: '16px',
                height: '16px',
              },
            },
            '.MuiTableRow-root:has(+ .MuiTableRow-root > .MuiTableCell-root.totals-row) .MuiTableCell-root': {
              borderBottom: 'none',
            },
            '.MuiTableRow-root:has(> .MuiTableCell-root.totals-row) + .MuiTableRow-root .MuiTableCell-root:first-of-type': {
              borderBottom: 'none',
            },
            '.MuiTableRow-root:has(> .MuiTableCell-root.totals-row) + .MuiTableRow-root .MuiTableCell-root': {
              borderTop: 'none',
            },
          },
        },
        {
          props: { size: 'tiles' },
          style: {
            borderSpacing: '0px 8px',
            marginTop: '-8px',
            opacity: 1,
            transition: defaultTheme.transitions.create(['opacity', 'filter'], {
              duration: defaultTheme.transitions.duration.shorter,
            }),
            [`.${tableHeadClasses.root} .${tableCellClasses.root}`]: {
              padding: '10px 16px',
              background: defaultTheme.palette.colors.neutrals[150],
              [`&.${tableCellClasses.paddingCheckbox}`]: {
                paddingLeft: '16px',
              },
            },
            [`.${tableCellClasses.root}`]: {
              borderBottom: 'none',
            },
            tbody: {
              [`.${tableRowClasses.root}`]: {
                [`.${tableCellClasses.root}:not(:nth-of-type(1))`]: {
                  padding: '12px 16px',
                },
                [`&:not(.${tableRowClasses.selected})`]: {
                  background: defaultTheme.palette.white.main,
                },
                [`.${tableCellClasses.paddingCheckbox}`]: {
                  paddingLeft: '16px',
                },
              },
              [`.${tableRowClasses.hover}:hover`]: {
                background: 'white',
                // @ts-ignore
                boxShadow: `0px 5px 24px ${alpha(defaultTheme.palette.colors.neutrals[800], 0.1)}`,
                transform: 'scale(1)',
              },
              [`.${tableRowClasses.root}:not(last-of-type) .${tableCellClasses.root}`]: {
                verticalAlign: 'middle',
              },
            },
          },
        },
        {
          props: { size: 'small' },
          style: {
            '.MuiTableCell-root': {
              verticalAlign: 'top',
              borderBottomColor: colors.neutrals[200],
              lineHeight: 1.5,
              '&.MuiTableCell-sizeSmall': {
                height: '48px',
              },
              '&:not(.MuiTableCell-paddingNone)': {
                padding: '12px 24px',
              },
              '&.editable': {
                padding: '7px 24px',
                '&:not(.edit-mode)': {
                  '& .MuiOutlinedInput-input, & .MuiOutlinedInput-root': {
                    cursor: 'pointer',
                  },
                  '& .MuiButtonBase-root': {
                    color: 'transparent',
                    pointerEvents: 'none',
                  },
                  '&.marked .MuiOutlinedInput-root': {
                    color: colors.primary[500],
                  },
                },
                '&:not(.MuiTableCell-paddingCheckbox)': {
                  paddingLeft: '8px',
                },
              },
              '&.MuiTableCell-paddingCheckbox': {
                padding: '16px 0 16px 32px',
                '.MuiFormControlLabel-root': {
                  ...flex.row,
                },
              },
              '& .MuiTextField-root': {
                maxWidth: 'inherit',
              },
              '&.MuiTableCell-head:not(.MuiTableCell-paddingCheckbox):not(.MuiTableCell-paddingNone)': {
                color: colors.neutrals[650],
                fontWeight: 600,
                padding: '10px 24px',
                '& .MuiTableSortLabel-root': {
                  gap: '0px',
                  whiteSpace: 'nowrap',
                  '& .MuiTableSortLabel-icon': {
                    height: '20px',
                    flexShrink: 0,
                  },
                },
              },
              '.MuiTableRow-root': {
                '.MuiTableCell-root:not(.MuiTableCell-paddingNone)': {
                  padding: '6px 24px',
                  height: '34px',
                },
              },
            },
          },
        },
        {
          props: { size: 'medium' },
          style: {
            '.MuiTableCell-head': {
              ...defaultTheme.typography.h7,
            },
            '.MuiTableCell-root': {
              height: 40,
              fontSize: 12,
              padding: '6px 12px',
              whiteSpace: 'nowrap',
              '&:not(.MuiTableCell-head)': {
                borderBottomColor: colors.neutrals[300],
              },
              svg: {
                height: 18,
                width: 18,
              },
              '&:last-of-type > .inline-actions-cell': {
                backgroundImage: `linear-gradient(to right, transparent, ${colors.neutrals[300]} 20%)`,
              },
            },
          },
        },
        {
          props: { size: 'large' },
          style: {
            [`.${tableHeadClasses.root} .${tableCellClasses.root}`]: {
              padding: '10px 16px',
            },
            [`.${tableCellClasses.root}`]: {
              padding: '32px 16px',
            },
          },
        },
      ],
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          [`&.${tableCellClasses.paddingCheckbox}`]: {
            [`.${checkboxClasses.root}:before`]: {
              content: "''",
              position: 'absolute',
              width: 36,
              height: 36,
              backgroundColor: 'transparent',
              borderRadius: 8,
              transition: defaultTheme.transitions.create(['background-color'], {
                duration: defaultTheme.transitions.duration.shorter,
              }),
              pointerEvents: 'none',
            },
            [`&:hover .${checkboxClasses.root}, .${checkboxClasses.root}:hover, .${checkboxClasses.root}.Mui-focusVisible`]: {
              [`&:not(.Mui-disabled):not(.Mui-checked):not(.${checkboxClasses.indeterminate}):before`]: {
                backgroundColor: alpha(colors.neutrals[500], 0.1),
              },
            },
            svg: {
              zIndex: 1,
            },
          },
        },
        head: {
          '&:first-of-type': {
            zIndex: 4,
          },
          zIndex: 3,
          '.MuiTableCell-root &': {
            zIndex: 2,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: '24px',
          boxShadow: 'none',
        },
      },
    },
    MuiTreeView: {
      styleOverrides: {
        root: {
          flexGrow: 1,
          '.MuiTreeItem-root + .MuiTreeItem-root': {
            marginTop: '4px',
          },
          '.MuiTreeItem-content': {
            padding: 0,
            transition: defaultTheme.transitions.create(['color'], {
              duration: defaultTheme.transitions.duration.shortest,
            }),
            color: colors.neutrals[800],
            '&:not(.Mui-expanded)': {
              color: colors.neutrals[600],
            },
            '&:hover, &.Mui-focused': {
              backgroundColor: 'transparent',
            },
            '&:hover': {
              color: colors.primary[500],
            },
            '&.Mui-selected': {
              backgroundColor: colors.primary[150],
            },
            '+ .MuiTreeItem-group': {
              margin: '8px 0',
            },
          },
          '.MuiTreeItem-label': {
            padding: '4px 8px',
          },
          '.MuiTreeItem-iconContainer:not(:empty) + .MuiTreeItem-label': {
            fontWeight: 600,
          },
          svg: {
            width: 18,
            height: 18,
            transition: defaultTheme.transitions.create(['transform']),
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          color: defaultTheme.palette.text.primary,
          borderRadius: 8,
          '&.MuiPaper-root': {
            boxShadow: 'none',
          },
          '&.grey-small': {
            backgroundColor: colors.neutrals[300],
            color: colors.neutrals[600],
            fontSize: 13,
            fontWeight: 500,
            paddingRight: '11px',
            paddingLeft: '6px',
            paddingTop: '1px',
            paddingBottom: '1px',
            '> div': { padding: 0 },
            '.MuiAlert-icon': {
              marginRight: 6,
            },
          },
        },
        message: {
          lineHeight: 1.5,
        },
        icon: {
          opacity: 1,
        },
        standardWarning: {
          backgroundColor: colors.orange[100],
          '.MuiAlert-icon': {
            color: colors.orange[500],
          },
        },
        standardError: {
          backgroundColor: colors.negative[150],
          '.MuiAlert-icon': {
            color: colors.negative[500],
          },
        },
        standardSuccess: {
          backgroundColor: colors.green[100],
          '.MuiAlert-icon': {
            color: gradients.green,
          },
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          '.SnackbarItem-wrappedRoot .SnackbarItem-contentRoot': {
            borderRadius: '12px',
            '&:not(.SnackbarItem-variantSuccess):not(.SnackbarItem-variantError):not(.SnackbarItem-variantWarning):not(.SnackbarItem-variantInfo)':
              {
                width: 500,
                borderRadius: '8px',
                backgroundColor: defaultTheme.palette.white.main,
                '.SnackbarItem-message': {
                  paddingTop: 12,
                  paddingRight: 8,
                  flexGrow: 1,
                },
              },
            boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
            fontWeight: 600,
            padding: '4px 16px',
            color: colors.neutrals[800],
            svg: {
              width: 20,
              height: 20,
            },
            '.SnackbarItem-message': {
              ...flex.itemsStart,
              gap: 8,
              paddingRight: 24,
            },
            '.SnackbarItem-action': {
              position: 'absolute',
              right: 8,
              top: 8,
              margin: '4px',
              padding: 0,
              'button svg': {
                color: colors.neutrals[600],
              },
            },
            '&.SnackbarItem-variantSuccess': {
              backgroundColor: colors.green[200],
              svg: {
                color: colors.green[400],
              },
            },
            '&.SnackbarItem-variantError': {
              backgroundColor: colors.negative[200],
              svg: {
                color: colors.negative[400],
              },
            },
            '&.SnackbarItem-variantWarning': {
              backgroundColor: colors.orange[200],
              svg: {
                color: colors.orange[400],
              },
            },
            '&.SnackbarItem-variantInfo': {
              backgroundColor: colors.neutrals[800],
              color: defaultTheme.palette.white.main,
              svg: {
                color: colors.neutrals[600],
              },
              '.SnackbarItem-action button svg': {
                color: defaultTheme.palette.white.main,
              },
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 12,
          fontWeight: 400,
          padding: '3px 10px',
          backgroundColor: alpha(colors.neutrals[850], 0.8),
          borderRadius: 12,
        },
        arrow: {
          color: colors.neutrals[800],
          '&:before': {
            borderTopLeftRadius: 2,
          },
        },
        popper: {
          positionVisibility: 'no-overflow',
          '&[data-popper-placement*="bottom"] .MuiTooltip-tooltip.MuiTooltip-tooltipArrow': {
            marginTop: 6,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          borderBottom: '1px solid',
          borderBottomColor: colors.neutrals[350],
          '.MuiButtonBase-root': {
            fontWeight: 600,
            minWidth: 'unset',
            padding: '12px 20px',
            '&:not(.Mui-selected)': {
              color: colors.neutrals[700],
            },
            '&:hover': {
              color: colors.primary[500],
            },
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          '.MuiButtonGroup-groupedOutlinedPrimary': {
            '&:hover + .MuiButtonGroup-groupedOutlined': {
              borderLeftColor: colors.primary[500],
            },
            '&.active-button': {
              color: colors.neutrals[800],
              borderColor: colors.primary[500],
              backgroundColor: colors.primary[150],
              '& + .MuiButtonGroup-groupedOutlined': {
                borderLeftColor: colors.primary[500],
              },
            },
          },
          '.MuiButtonGroup-groupedContainedPrimary': {
            '&:not(:last-of-type)': {
              borderColor: colors.primary[350],
            },
            '&.active-button': {
              backgroundColor: colors.primary[650],
            },
            '&.Mui-disabled': {
              borderColor: colors.neutrals[350],
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          gap: 16,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '18px',
          lineHeight: 1.5,
          padding: '32px 64px 0 32px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          minHeight: 48,
          padding: '0 32px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          borderTop: `1px solid ${colors.neutrals[350]}`,
          gap: 1,
          padding: '16px 24px 16px',
        },
      },
    },
  },
  breakpoints: {
    values: {
      ...defaultTheme.breakpoints.values,
      desktop: 1920,
      laptopBig: 1600,
      laptop: 1440,
    },
  },
});

const AvThemeProvider = ({ children }) => (
  <ThemeProvider theme={appTheme}>
    <CssBaseline />
    <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
  </ThemeProvider>
);
export default AvThemeProvider;
